import { Routes, Route, Outlet } from "react-router-dom";
import Landing from "./Landing";
import { Privacy, Terms, Support } from "./PrivacyAndTerms";
import { Nav } from "./Nav";
import RemoveAccount from "./RemoveAccount";
export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="support" element={<Support />} />
          <Route path="remove-account" element={<RemoveAccount />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div className="bg-white">
      <Nav />
      <Outlet />
    </div>
  );
}
function Home() {
  return (
    <div>
      <Landing />
    </div>
  );
}
